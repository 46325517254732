import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './EventsPage.css';
import { LoadEvents, OpenPopup } from '../../Actions';
import i18next from 'i18next';
import classNames from 'classnames';
import { baseUrl } from '../../Actions/utilActions';
import { Button } from '../../Components';
import moment from 'moment';

function mapStateToProps({ app, user, event }) {
    return { app, user, event };
}

const mapDispatchToProps = dispatch => {
    return {
        openPopup: data => {
            dispatch(OpenPopup(data));
        },
        list: () => {
            dispatch(LoadEvents())
        }
    };
};

class _EventsPage extends Component {
    static propTypes = {
        user: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object
    };
    constructor(props) {
        super(props);
        this.state = {
            selected: 0,
        }
    }
    componentDidMount() {
        this.props.list();
    }
    getYears() {
        var years = [];
        this.getEventList().forEach(element => {
            if (!years.includes(element.year)) {
                years.push(element.year);
            }
        });
        years.sort((a, b) => b - a);
        // console.log(years)
        return years;
    }
    goToDetails(id) {
        this.props.history.push("/events/" + id)
    }
    LanguageDate(text){
        if (this.props.app.language === 'ta' ){
            var months = [{"en":"January", "ta":"ஜனவரி"},{"en":"February", "ta":"பிப்ரவரி"},{"en":"March", "ta":"மார்ச்"},{"en":"April", "ta":"ஏப்ரல்"},{"en":"May", "ta":"மே"},{"en":"June", "ta":"ஜூன்"},{"en":"July", "ta":"ஜூலை"},{"en":"August", "ta":"ஆகஸ்ட்"},{"en":"September", "ta":"செப்டம்பர்"},{"en":"October", "ta":"அக்டோபர்"},{"en":"November", "ta":"நவம்பர்"},{"en":"December", "ta":"டிசம்பர்"}]
            months.forEach(x=>{
                text = text.replace(x.en, x.ta);
            })
        }
        return text;
    }
    getEventList(){
        return this.props.event?.list.filter(x=> this.props.user.role === "Admin" ? true : x.published === true) || [];
    }
    render() {
        const years = this.getYears();
        return (
            <div className="contact_container">
                <div className='contact_page_header'>
                    <h3>{i18next.t('events.pagetitle')}</h3>
                </div>
                {
                    this.props.user.role === "Admin" &&
                    <div className='create_event'>
                        <Button onClick={() => { this.props.history.push("/editevent/new") }}>Create New Event</Button>
                    </div>
                }
                <div className='events'>
                    <div className='events_tab_container'>
                        {
                            years.map((x, i) => {
                                return (
                                    <div key={i} className={classNames('events_year_tab', this.state.selected === i && 'events_tab_selected')} onClick={() => { this.setState({ selected: i }) }}>
                                        {x}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='event_container'>
                        {
                            years.length > 0 && this.getEventList().filter(x => x.year === years[this.state.selected]).length > 0 ? this.getEventList().filter(x => x.year === years[this.state.selected]).map((x, i) => {
                                return (
                                    <div key={i} className={classNames('event_content', 'event_clickable')}>
                                        <div className={classNames('event_pic')}  onClick={() => { this.goToDetails(x.eventId) }}>
                                            <img alt='BAPA' src={baseUrl + "/api/events/Image/" + x.picture} className="event_image"></img>
                                        </div>
                                        {
                                            this.props.user.role === "Admin" &&
                                            <div className={classNames('event_name')}>
                                                <Button onClick={()=>{this.props.history.push("/editevent/"+ x.eventId)}}>Edit</Button>
                                                <Button onClick={()=>{this.props.history.push("/events/"+ x.eventId)}}>Notes</Button>
                                            </div>
                                        }
                                        <div className={classNames('event_name')}>
                                            {this.props.app.language === 'ta' ? x.nameTamil : x.name}
                                        </div>
                                        <div className={classNames('event_date')}>
                                            {this.LanguageDate(moment(x.startDate).format("MMMM D"))}
                                            { x.endDate && ( "-" + this.LanguageDate(moment(x.endDate).format("MMMM D")))}
                                        </div>
                                    </div>
                                )
                            }) :
                                <div className='no_events_available'>
                                    {this.props.app.language === 'ta' ? "நிகழ்ச்சிகள் விவரங்கள் எதுவும் கிடைக்கவில்லை." : "No event details found."}
                                </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export const EventsPage = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_EventsPage)
);