import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './TableView.css';
import { MenuHolder } from './MenuHolder';
import { getAction, getAddress } from '../../Utils/utils';
import { Icon } from './Icon';

export class TableView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterText: "",
      actionOpenId: -1,
      pageSize: 10,
      pageIndex: 1,
      selected: false
    }
  }
  static propTypes = {
    columns: PropTypes.array,
    data: PropTypes.any
  };
  componentDidUpdate(prevProps) {
    if (prevProps.data.length !== this.props.data.length) {
      this.setState({ actionOpenId: -1, pageSize: 10, pageIndex: 1, selected: false });
    }
  }
  renderCloumnValue(row, col) {
    switch (col.func) {
      case "address":
        return getAddress(row);
      case "action":
        return getAction(row, col, this.state.actionOpenId, (id) => this.setState({ actionOpenId: id }));
      default:
        return row[col.key];
    }
  }
  getPageIndex = (x, i) => {
    return i < (this.state.pageSize * this.state.pageIndex) && i >= (this.state.pageSize * (this.state.pageIndex - 1))
  }
  changePage(i, data) {
    if (this.state.pageIndex > 1 && i === -1) {
      this.setState({ pageIndex: this.state.pageIndex + i });
    }
    if ((this.state.pageIndex * this.state.pageSize) < data.length && i === 1) {
      this.setState({ pageIndex: this.state.pageIndex + i });
    }
  }
  filterData(data) {
    var result = [];
    //first filter by tabs.
    result = data?.filter((x,i)=> {
      if (i < 5){
        console.log(x);
      }
      var yes = false;
      this.props.columns.forEach(element => {
        if (i < 5){
          // console.log(x[element.key], element);
        }
        if (this.state.filterText === ""){
          yes = true;
        } else if ((x[element.key] || "").toLowerCase().indexOf(this.state.filterText.toLowerCase()) > -1){
          yes = true;
        }
      });
      return yes;
    });
    return result;
  }
  render() {
    var data = this.filterData(this.props.data) || [];
    if (!data) {
      return null;
    }
    return (
      <React.Fragment>
        <div className='filter'>
          <div className='filter_container'>
            <div className='filter_icon'>
              <Icon>search</Icon>
            </div>
            <input type='text' className='filter_input' placeholder='Search..' value={this.state.filterText} onChange={(e) => { this.setState({ filterText: e.target.value }) }} />
          </div>
        </div>
        <table className='table_view'>
          <thead>
            <tr>
              {
                this.props.columns.map((x, i) => {
                  return (
                    <th key={i} className='table_view_head_row'>{x.name}</th>
                  )
                })
              }
            </tr>
          </thead>
          <tbody>
            {
              data?.filter(this.getPageIndex).map((x, i) => {
                return (
                  <tr key={i} className='user_row'>
                    {
                      this.props.columns.map((y, j) => {
                        return (
                          <th key={j} className='table_view_column'>{this.renderCloumnValue(x, y)}</th>
                        )
                      })
                    }
                  </tr>
                )
              })
            }
            {
              data.length === 0 &&
              <tr>
                <td colSpan={this.props.columns.length} className='no_records'>No records found.</td>
              </tr>
            }
            <tr>
              <td colSpan={this.props.columns.length} className='user_head_row'>
                <div className="tv_footer_action">
                  <div style={{ flex: "1 1 100%" }}></div>
                  <p className="tv_row_per_page">Rows per page:</p>
                  <div className="tv_page_size_dd" onClick={() => this.setState({ selected: true })}>
                    <div className="tv_page_size_text" role="button">{this.state.pageSize}</div>
                    <svg className="tv_page_size_arrow" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                      <path d={"M7 10l5 5 5-5z"}></path>
                    </svg>
                  </div>
                  <MenuHolder open={this.state.selected} top="22px" closePopup={() => this.setState({ selected: false })} outsideClickClose>
                    {[10, 20, 50, 100].map(x => {
                      return (
                        <div key={x} className={classNames("mh_item center", this.state.pageSize === x && "mh_selected")} onClick={() => { this.setState({ pageSize: x, selected: false, pageIndex: 1 }) }}>{x}</div>
                      )
                    })}
                  </MenuHolder>
                  <p className="tv_row_per_page">{`${((this.state.pageIndex - 1) * this.state.pageSize) + 1}-${this.state.pageIndex * this.state.pageSize <= data?.length ? (this.state.pageIndex * this.state.pageSize) : data?.length} of ${data?.length}`}</p>
                  <div className="tv_page_navigation">
                    <div className={classNames("tv_page_change", this.state.pageIndex === 1 && "tv_page_change_disabled")} onClick={() => this.changePage(-1, data)}>
                      <svg className="tv_page_arrow" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                        <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"></path>
                      </svg>
                    </div>
                    <div className={classNames("tv_page_change", (this.state.pageIndex * this.state.pageSize) >= data?.length && "tv_page_change_disabled")} onClick={() => this.changePage(1, data)}>
                      <svg className="tv_page_arrow" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                        <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}
