import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './EventsEditPage.css';
import { LoadEvents, OpenPopup, SaveNewEvent, UpdateEventProp } from '../../Actions';
import i18next from 'i18next';
import classNames from 'classnames';
import { baseUrl } from '../../Actions/utilActions';
import { Button, Calendar, CheckBox, TextBox } from '../../Components';

function mapStateToProps({ app, user, event }) {
    return { app, user, event };
}

const mapDispatchToProps = dispatch => {
    return {
        openPopup: data => {
            dispatch(OpenPopup(data));
        },
        list: data => {
            dispatch(LoadEvents(data));
        },
        updateEvent: (data) =>{
            dispatch(UpdateEventProp(data));
        },
        save: (data, id) =>{
            dispatch(SaveNewEvent(data, id));
        }
    };
};

class _EventsEditPage extends Component {
    static propTypes = {
        user: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object
    };
    constructor(props) {
        super(props);
        this.state = {
            selected: 0,
        }
    }
    componentDidMount() {
        this.props.list({edit: this.props.match.params.id});
        var fileuploader = document.getElementById("fileuploader");
        fileuploader.addEventListener('dragover', this.preventDefaults);
        fileuploader.addEventListener('dragenter', this.preventDefaults);
        fileuploader.addEventListener('dragleave', this.preventDefaults);
        fileuploader.addEventListener('drop', (e) => {this.fileUpload(e, fileuploader) });
    }
    preventDefaults(e) {
        e.preventDefault();
        e.stopPropagation();
    }
    fileUpload(e) {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.effectAllowed = "none";
        e.dataTransfer.dropEffect = "none";
        const files = e.dataTransfer.files;
        for (const file of files) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = (e) => {
                this.props.updateEvent({"picture":e.target.result});
            };
        }
    }
    fileUploadInput(e){
        e.preventDefault();
        e.stopPropagation();
        const files = e.target.files;
        for (const file of files) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = (e) => {
                this.props.updateEvent({"picture":e.target.result});
            };
        }
    }
    getPictureURL(pic){
        if (pic.startsWith("data:")){
            return pic
        } else {
            return baseUrl + "/api/events/Image/" + pic;
        }
    }
    updateValueOnChange(e){
        this.props.updateEvent({[e.target.name]: e.target.value});
    }
    render() {
        return (
            <div className="contact_container">
                <div className='contact_page_header'>
                    <h3>{i18next.t('events.editpagetitle')}</h3>
                </div>
                <div className='edit_event_form'>
                    <div className='edit_event_form_col'>
                        <div className='register_form_column'>
                            <TextBox name="name" type="text" mandatory value={this.props.event.editEvent.name} title={i18next.t('events.eventNameEn')} placeholder={i18next.t('events.eventNameEn').replace(" *", "")} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                        </div>
                        <div className='register_form_column'>
                            <TextBox name="nameTamil" type="text" mandatory value={this.props.event.editEvent.nameTamil} title={i18next.t('events.eventNameTa')} placeholder={i18next.t('events.eventNameTa').replace(" *", "")} onChange={(e) => this.updateValueOnChange(e)}></TextBox>
                        </div>
                        <div className='register_form_column'>
                            <Calendar name='startDate' title={i18next.t('events.eventStartDate')} value={this.props.event.editEvent.startDate} onChange={(e)=>this.updateValueOnChange(e)}></Calendar>
                        </div>
                        <div className='register_form_column'>
                        <Calendar name='endDate' title={i18next.t('events.eventEndDate')} value={this.props.event.editEvent.endDate} onChange={(e)=>this.updateValueOnChange(e)}></Calendar>
                        </div>
                        <div className='register_form_column'>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <CheckBox name="published" value={this.props.event.editEvent.published} onChange={(e)=>this.updateValueOnChange(e)}></CheckBox> Publish
                            </div>
                        </div>
                    </div>
                    <div className='edit_event_form_col'>
                        <div id="fileuploader" className={classNames('edit_event_image_container', 'event_clickable')} onClick={() => { document.getElementById("file-input").click() }}>
                            <div className={classNames('edit_event_pic')}>
                                {
                                    !this.props.event.editEvent.picture ?
                                        <div>
                                            Upload your event picture.
                                            <p className='uploadImageSubTitle'>Click or drag and drop your image here.</p>
                                        </div> :
                                        <img alt='BAPA' src={this.getPictureURL(this.props.event.editEvent.picture)} className="edit_event_image"></img>
                                }
                                <input type="file" id="file-input" multiple hidden onChange={(e)=>{ this.fileUploadInput(e)}} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='edit_event_form'>
                    <div className='edit_event_action_buttons'>
                        <Button onClick={()=> { this.props.save(this.props.event.editEvent, this.props.match.params.id); this.props.history.push("/events"); }}>Save</Button>
                        <Button onClick={() => { this.props.history.push("/events") }}>Cancel</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export const EventsEditPage = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_EventsEditPage)
);