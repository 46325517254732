// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit_event_form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.edit_event_form_col {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1 1;
}

.edit_event_image_container {
    border: 1px solid #e9ddd0;
    padding: 10px;
}

.edit_event_pic {
    border: 1px dashed #e9ddd0;
    border-radius: 25px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-align: center;
}
.edit_event_action_buttons{
    margin-top: 30px;
    width: 100%;
    border-top: 1px solid #e9ddd0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.edit_event_image{
    width: 100%;
    height: 100%;
    margin: auto;
}
.uploadImageSubTitle{
    font-size: 12px;
    color: gray;
}`, "",{"version":3,"sources":["webpack://./src/Pages/EventsEditPage/EventsEditPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,SAAO;AACX;;AAEA;IACI,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,0BAA0B;IAC1B,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,WAAW;IACX,6BAA6B;IAC7B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,YAAY;IACZ,YAAY;AAChB;AACA;IACI,eAAe;IACf,WAAW;AACf","sourcesContent":[".edit_event_form {\r\n    display: flex;\r\n    flex-direction: row;\r\n    flex-wrap: wrap;\r\n    width: 100%;\r\n}\r\n\r\n.edit_event_form_col {\r\n    display: flex;\r\n    flex-direction: column;\r\n    flex-basis: 100%;\r\n    flex: 1;\r\n}\r\n\r\n.edit_event_image_container {\r\n    border: 1px solid #e9ddd0;\r\n    padding: 10px;\r\n}\r\n\r\n.edit_event_pic {\r\n    border: 1px dashed #e9ddd0;\r\n    border-radius: 25px;\r\n    height: 250px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    overflow: hidden;\r\n    text-align: center;\r\n}\r\n.edit_event_action_buttons{\r\n    margin-top: 30px;\r\n    width: 100%;\r\n    border-top: 1px solid #e9ddd0;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n.edit_event_image{\r\n    width: 100%;\r\n    height: 100%;\r\n    margin: auto;\r\n}\r\n.uploadImageSubTitle{\r\n    font-size: 12px;\r\n    color: gray;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
