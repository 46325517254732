import React, { Component } from 'react';
import { Router, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './App.css';
import { PrivateRoute, PublicRoute } from './Route';
import { AboutUsPage, BoardMembersPage, ContactUsPage, EventsPage, HomePage, NotFoundPage, Privacypolicy, MembershipRegistrationPage, MembersLoginPage, FinanceReportPage, DeveloperPage, VerifyLinkPage, MyPage, AdminPage, ErrorPage, EventDetailsPage, Diwali2024Page, EventsEditPage } from './Pages';
import { OAuth2RedirectHandler } from './Utils/OAuth2RedirectHandler';
import { Logout } from './Utils/Logout';
import { ActivateAccount } from './Utils/ActivateAccount';

export default class App extends Component {
  render() {
    return (
      <Router history={createBrowserHistory()}>
        <Switch>
          <PublicRoute component={Diwali2024Page} exact path="/diwali2024" />
          <PrivateRoute component={MyPage} exact path="/mypage" />
          <PrivateRoute component={AdminPage} exact path="/admindashboard" />
          <PublicRoute component={ActivateAccount} exact path="/activateaccount" />
          <PublicRoute component={OAuth2RedirectHandler} exact path="/redirect" />
          <PublicRoute component={VerifyLinkPage} exact path="/verify" />
          <PublicRoute component={DeveloperPage} exact path="/developer" />
          <PublicRoute component={DeveloperPage} exact path="/dev" />
          <PublicRoute component={Privacypolicy} exact path="/privacypolicy" />
          <PublicRoute component={ContactUsPage} exact path="/contactus" />
          <PublicRoute component={MembersLoginPage} exact path="/memberlogin" />
          <PublicRoute component={AboutUsPage} exact path="/aboutus" />
          <PublicRoute component={BoardMembersPage} exact path="/boardmembers" />
          <PublicRoute component={FinanceReportPage} exact path="/finance-report/:year" />
          <PublicRoute component={EventsPage} exact path="/events" />
          <PublicRoute component={EventDetailsPage} exact path="/events/:id" />
          <PublicRoute component={MembershipRegistrationPage} exact path="/memberregistration" />
          <PublicRoute component={HomePage} exact path="/" />
          <PublicRoute component={HomePage} exact path="/adminin" />
          <PublicRoute component={HomePage} exact path="/adminout" />
          <PublicRoute component={Logout} exact path="/logout" />
          {/* <PublicRoute component={ElectionPage} exact path="/election2024" /> */}
          <PublicRoute component={NotFoundPage} exact path="/not-found" />
          <PublicRoute component={ErrorPage} exact path="/error" />
          <PrivateRoute component={EventsEditPage} exact path="/editevent/:id" />
          <Redirect to="/" />
        </Switch>
      </Router>
    );
  }
}