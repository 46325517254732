import Axios from "axios";
import { accessTokenKey, baseUrl, getToken } from "./utilActions";
import { AppActions } from "./appActions";

function prefix(action) {
  return `EVENT_${action}`;
}
  
export const EventActions = {
  LIST: prefix('LIST'),
  DETAIL: prefix('DETAIL'),
  UPDATE: prefix('UPDATE'),
  UPDATE_PROP: prefix('UPDATE_PROP'),
  NOTES: prefix('NOTES')
};


export const EventUpdate = data => dispatch => {
  dispatch({ type: EventActions.UPDATE, payload: data });
};

export const UpdateEventProp = data => dispatch => {
  dispatch({ type: EventActions.UPDATE_PROP, payload: data });
};

export const LoadEvents = data => dispatch => {
  Axios.get(`${baseUrl}/api/events/all`, { headers: { authorization: "Bearer " + getToken(accessTokenKey) } }).then(result => {
    dispatch({ type: EventActions.LIST, payload: result.data });
    if (data?.edit){
      var editData = result.data.filter(x=>x.eventId+"" === data.edit);
      if (editData.length === 1){
        dispatch({ type: EventActions.UPDATE_PROP, payload: editData[0] });
      } else {
        dispatch({ type: EventActions.UPDATE_PROP, payload: null });
      }
    }
  }).catch(error => {
    dispatch({ type: AppActions.ERROR, payload: { response: error.response, request: error } });
  });
}

export const LoadEventDetails = (id) => dispatch => {
  dispatch(LoadEvents());
  Axios.get(`${baseUrl}/api/events/${id}`, { headers: { authorization: "Bearer " + getToken(accessTokenKey) } }).then(result => {
    dispatch({ type: EventActions.DETAIL, payload: result.data });
  }).catch(error => {
    dispatch({ type: EventActions.DETAIL, payload: {} });
  });
}

export const AddNotesToEvent = (id, data) => dispatch => {
  Axios.post(`${baseUrl}/api/events/${id}/addNotes`,data, { headers: { authorization: "Bearer " + getToken(accessTokenKey) } }).then(result => {
    dispatch({ type: EventActions.NOTES, payload: result.data });
  }).catch(error => {
    dispatch({ type: AppActions.ERROR, payload: { response: error.response, request: error } });
  });
}

export const AddCommentsToEvent = (id, data) => dispatch => {
  Axios.post(`${baseUrl}/api/events/${id}/addComments`,data, { headers: { authorization: "Bearer " + getToken(accessTokenKey) } }).then(result => {
    dispatch({ type: EventActions.NOTES, payload: result.data });
  }).catch(error => {
    dispatch({ type: AppActions.ERROR, payload: { response: error.response, request: error } });
  });
}

export const DeleteComment = (id, commentId) => dispatch => {
  Axios.delete(`${baseUrl}/api/events/${id}/deleteComments/${commentId}`, { headers: { authorization: "Bearer " + getToken(accessTokenKey) } }).then(result => {
    dispatch({ type: EventActions.NOTES, payload: result.data });
  }).catch(error => {
    // dispatch({ type: AppActions.ERROR, payload: { response: error.response, request: error } });
  });
}

export const SaveNewEvent = (data, id) => dispatch => {
  if (id === "new"){
    Axios.post(`${baseUrl}/api/events/createEvent`, data, { headers: { authorization: "Bearer " + getToken(accessTokenKey) } }).then(result => {
      dispatch({ type: EventActions.LIST, payload: result.data });
    }).catch(error => {
      dispatch({ type: AppActions.ERROR, payload: { response: error.response, request: error } });
    });
  } else {
    Axios.put(`${baseUrl}/api/events/updateEvent`, data, { headers: { authorization: "Bearer " + getToken(accessTokenKey) } }).then(result => {
      dispatch({ type: EventActions.LIST, payload: result.data });
    }).catch(error => {
      dispatch({ type: AppActions.ERROR, payload: { response: error.response, request: error } });
    });
  }
}
