import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Calendar.css';
import { Icon } from './Icon';
import moment from 'moment';

export class Calendar extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        title: PropTypes.string,
        onChange: PropTypes.func,
    };
    constructor(props) {
      super(props);
      this.state = {
        open: false,
        month: 0
      };
    }
    onValueChange(val){
        this.props.onChange({
            target : {
                name: this.props.name,
                value: val
            }
        });
        this.setState({open: false});
    }
    render() {
        const week = [moment().weekday(0).format('ddd'),
        moment().weekday(1).format('ddd'),
        moment().weekday(2).format('ddd'),
        moment().weekday(3).format('ddd'),
        moment().weekday(4).format('ddd'),
        moment().weekday(5).format('ddd'),
        moment().weekday(6).format('ddd')];
        var currentMonthStartingDay = week.findIndex((x)=> x === moment().startOf('month').add(this.state.month, 'M').format('ddd'));
        var main = moment().startOf('month').add(this.state.month, 'M');
        return (
            <div className="calendar_container" onMouseLeave={()=>this.setState({open: false})}>
                <div>
                    <p className="calendar_information_text">{this.props.title}</p>
                    <div className="calendar_input_container" onClick={()=>this.setState({open: true})}>
                        <input className="calendar_input" value={this.props.value ? moment(this.props.value).format('DD/MMM/YYYY') : ''} onKeyUp={(e)=>{ if (e.key === "Backspace") { this.onValueChange(null) }}}/>
                        <Icon>calendar</Icon>
                    </div>
                </div>
                {
                    this.state.open &&
                    <div className="calendar_view">
                        <div className="calendar_actions">
                            <div className="calendar_action_item calendar_prev_year" onClick={()=>this.setState({month: this.state.month - 12})}>{'<<'}</div>
                            <div className="calendar_action_item calendar_prev_month" onClick={()=>this.setState({month: this.state.month - 1})}>{'<'}</div>
                            <div className="calendar_action_item calendar_current_date">{main.format('MMM YYYY')}</div>
                            <div className="calendar_action_item calendar_next_month" onClick={()=>this.setState({month: this.state.month + 1})}>{'>'}</div>
                            <div className="calendar_action_item calendar_next_year" onClick={()=>this.setState({month: this.state.month + 12})}>{'>>'}</div>
                        </div>
                        <div className="calendar_row">
                            {
                                week.map((x,i)=>{
                                    return(
                                        <div key={i} className="calendar_column">
                                            {
                                                x
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            [0,1,2,3,4,5].map((a,i)=>{
                                if (a === 0){
                                    main.add(-(currentMonthStartingDay+1), 'd');
                                }
                                return(
                                    <div key={i} className="calendar_row">
                                        {
                                            [0,1,2,3,4,5,6].map((b)=>{
                                                var date = main.add(1, 'd');
                                                var val = date.valueOf();
                                                var curMonth = date.format('MMM') === moment().add(this.state.month, 'M').format('MMM');
                                                var number = date.format('DD');
                                                return(
                                                    <div className={classNames(["calendar_column","calendar_date", val === this.props.value && "calendar_selected_date",curMonth ? "calendar_current_month" : "calendar_not_corrent_month"])} onClick={()=>this.onValueChange(val)}>
                                                        {
                                                            number
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        );
    }
}
